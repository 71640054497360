import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from '@utils'
import Seo from "@components/seo";
import Layout from "@layout";
import Header from '@layout/header/layout-01';
import StickyMenu from "@containers/sticky-menu";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import QuoteForm from "@containers/translation-quote-form";
import FeatureGrid from "@components/feature-grid";
import IntroAreaOne from "@components/introarea/layout-4";
import CtaArea from "@containers/cta/layout-02";
import CaseStudy from "@containers/global/case-study";
import BoxSection from "@components/BoxSection/layout-three/layout-3";
import KeyBenifits from '@containers/ai-voiceover/key-benifits'
import { caseStudiesData } from "@constants";
import IntroAreaReverse from "@components/introareareverse/layout-5";
import IntroArea from "@components/introarea/layout-6";
import ContactArea from "@containers/contact-us";
const AiVoiceOver = ({ location, data }) => {
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  const content = normalizedData(data?.page.content || []);
  return (
    <Layout location={location}>
      <Seo
        title="AI Voiceover"
        description="Voiceover"
        noindex={true}
      />
      <Header
        data={{
          ...globalContent["header"],
          ...globalContent['menu'],
          ...data.site.siteMetadata,
        }}
      />
      <StickyMenu data={content["sticky-menu-data"]} />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["ai-voiceover-page-header"]} />
        <QuoteForm />
        <FeatureGrid data={content["ai-voiceover-features"]} />
        <IntroAreaOne layout={2} data={content["ai-voiceover-data"]} />
        <IntroArea layout={3} data={content["why-ai-voiceover-body"]} />
        <CtaArea data={content["ai-voiceover-cta-data"]} />
        <KeyBenifits data={content['key-benifits']} />
        <IntroAreaReverse layout={2} data={content["customization-voiceover-body"]} />
        <BoxSection layout={5} data={content["empowering-section-body"]} />
        <CaseStudy layout={3} data={caseStudiesData} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
}

export const query = graphql`
  query AiVoiceOver {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(
      title: { eq: "ai-voiceover" }
      pageType: { eq: "innerpage" }
    ) {
      content {
        ...PageContent
      }
    }
  }
`;

AiVoiceOver.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default AiVoiceOver;
